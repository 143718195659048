<template>
    <div class="header-container">
        <div class="btn-drawer" @click="$emit('openDrawer')">
            <a-icon type="bars" style="font-size:28px"/>
        </div>

        <div class="header-title">
            <div class="supTitle" v-if="title.supName && !$store.state.isMobile">{{ title.supName }} ></div>
            <div class="title">{{ title.name }}</div>
        </div>
        <div class="header-right-box">
            <a-dropdown placement="bottomCenter">
                <div class="user-box">
                    <div class="header-avatar">
                        <img :src="info.face_url" alt="" srcset=""/>
                    </div>
                    <div class="header-name">
                        <div class="name">{{ info.uname | kong }}</div>
                        <div class="tips">{{ info.role_name | kong }}</div>
                    </div>
                    <i class="iconfont">&#xe664;</i>
                </div>

                <a-menu slot="overlay">
                    <a-menu-item>
                        <a-icon type="user"/>
                        <span>{{ info.uid }}</span>
                    </a-menu-item>
                    <a-menu-divider/>
                    <a-menu-item @click="logout">
                        <a-icon style="margin-right: 8px" type="poweroff"/>
                        <span>退出登录</span>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
    </div>
</template>

<script>
    import tool from "@/utils/tool";
    import {whitePath} from "@/common/config";
    export default {
        name: "Header",
        components: {},
        data() {
            return {
                info: {},
                title: {
                    name: "用户管理",
                },
            };
        },
        computed: {},
        created() {
            if (!window.localStorage.getItem("token")) {
                this.$router.push("/login");
            } else {
                this.getInfo()
            }

        },
        mounted() {
            console.log(this.$router.options.routes[2].children);
            tool.$on("setName", (res) => {
                console.log(res);
                this.title = res;
            });

            tool.$on("changeMenu", (res) => {
                this.getInfo();
            });

            // this.getInfo();
        },
        methods: {
            getInfo() {
                this.$api.get("v1/user/info").then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.info = res.data;
                        window.localStorage.setItem("info", JSON.stringify(res.data));
                        // tool.$emit('setInfo',res.data)
                    } else if (res.code == 106) {
                        this.$router.push("/login/reg");
                    } else if (res.code == 0) {
                        this.$message.error(res.message);
                        this.$router.push("/login");
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            logout() {
                window.localStorage.clear();
                window.localStorage.setItem('whitePath', whitePath.join(','))
                this.$router.push("/login");
            },
        },
    };
</script>

<style lang="less" scoped>
    .header-container {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 300px);
        height: 50px;
        overflow: hidden;
        background: #fff;
        position: fixed;
        top: 0;
        z-index: 99;
        align-items: center;

        .btn-drawer {
            display: none;
            margin-left: 5px;
        }

        .header-title {
            margin-left: 40px;
            font-size: 16px;
            color: #303133;
            display: flex;

            .supTitle {
                color: #cccccc;
            }
        }

        .header-right-box {
            margin-right: 40px;
            display: flex;
            align-items: center;

            .iconfont {
                font-size: 32px;
            }

            .user-box {
                display: flex;
                align-items: center;

                .header-avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-left: 17px;
                    // background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/common/logo1.png);
                    // background-position: center;
                    // background-size: 100%;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }

                .header-name {
                    margin-left: 7px;

                    .name {
                        font-size: 16px;
                        color: #333333;
                    }

                    .tips {
                        font-size: 12px;
                        color: #cccccc;
                    }
                }
            }
        }
    }

    @media screen and(max-width: 750px) {
        .header-container {
            width: 100%;

            .btn-drawer {
                display: block;
            }

            .header-title {
                margin-left: 10px;
            }

            .header-right-box {
                margin-right: 0px;
            }
        }
    }
</style>
