<template>
    <div class="container">
        <a-drawer
                title="none"
                placement="left"
                :closable="false"
                :visible="visible"
                :after-visible-change="afterVisibleChange"
                @close="onClose"
                v-if="$store.state.isMobile"
        >
            <Slide/>
        </a-drawer>
        <Slide v-else/>
        <div class="box">
            <div class="left-box"></div>
            <div class="right-box">
                <Header @openDrawer="visible=!visible"/>
                <div class="header-block"></div>
                <div class="content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Slide from "@/components/slide/Index";
    import Header from "@/components/header/Index";
    import {whitePath} from "@/common/config";
    import tool from '@/utils/tool'

    export default {
        name: "Index",
        components: {
            Slide,
            Header,
        },
        data() {
            return {
                visible: false,
                isMoble: false,
                whitePath,
                buttons: []
            };
        },
        computed: {},
        created() {
            if (!window.localStorage.getItem("token")) {
                this.$router.push("/login");
            } else {
                this.getMenus()
            }


            // this.getMenus()
            if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
                //显示移动端的代码
                console.log('移动端')
                this.$store.state.isMobile = true
            } else {
                //显示非移动端的代码
                console.log('pc端')
                this.$store.state.isMobile = false
            }
        },
        watch: {
            '$route.path': {
                handler(val) {
                    console.log(val)
                }
            }
        },
        mounted() {
            tool.$on('changeMenu', (res) => {
                console.log(123);
                this.getMenus();
            })
        },
        methods: {
            getMenus() {
                if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)))
                    this.$store.state.isMobile = false;
                // this.$api.get("v2/adminRules/sel").then((res) => {
                this.$api.post("v2/adminRules/menus", {is_mobile: this.$store.state.isMobile}).then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        let list = res.data.map(item => {
                            item.isChild = false
                            for (let i = 0; i < item.children.length; i++) {
                                if (item.children[i].type == 1) {
                                    item.isChild = true
                                }
                            }
                            item.active = false;
                            return item
                        })
                        this.$store.state.menus = list
                        this.whitePath = whitePath
                        this.buttons = []
                        this.handleMenus(list)
                        console.log('whitePath', this.whitePath, this.buttons)
                        this.$store.state.buttons = this.buttons
                        window.localStorage.setItem('whitePath', this.whitePath.join(','))
                        window.localStorage.setItem('whiteBtns', this.buttons.join(','))
                        if (!this.whitePath.includes(this.$route.path) || this.$route.path == '/') {
                            this.$router.push(list[0].router)
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                });
            },

            //递归处理页面
            handleMenus(child) {
                for (let i = 0; i < child.length; i++) {
                    if (child[i].type == 1) {
                        this.whitePath.push(child[i].router)
                    }

                    if (child[i].type == 2) {
                        this.buttons.push(child[i].alias)
                    }

                    if (child[i].children.length > 0) {
                        this.handleMenus(child[i].children)
                    }
                }
            },

            afterVisibleChange(val) {
                console.log("visible", val);
            },
            showDrawer() {
                this.visible = true;
            },
            onClose() {
                this.visible = false;
            },
        },
    };
</script>

<style lang="less" scoped>
    .container {
        // background-color: #ff0;
        .box {
            display: flex;
            width: 100vw;

            .left-box {
                width: 300px;
                height: 100%;
            }

            .right-box {
                flex: 1;
                height: 100vh;
                background-color: #f6f7fa;
                overflow-x: hidden;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 2px;
                    background-color: rgba(7, 16, 32, 0.5);
                    height: 180px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: skyblue;
                }

                ::-webkit-scrollbar-track {
                    border-radius: 1em;
                    background-color: rgba(50, 50, 50, 0.1);
                }

                // scrollbar-color: @primary-color @primary-2;
                scrollbar-width: thin;
                -ms-overflow-style: none;
            }

            .header-block {
                height: 50px;
            }

            .content {
                margin: 40px;
            }
        }
    }

    @media screen and(max-width: 750px) {
        .container {
            .box {
                .content {
                    margin: 10px;
                }
            }
        }

        .left-box {
            // width: 0!important;
            display: none;
        }
    }

    /deep/ .ant-drawer-body {
        padding: 0;
    }

    /deep/ .ant-drawer-header {
        display: none;
    }
</style>
