<template>
    <div class="slide-container ">
      <div class="container">
      <div class="logo-container">
        <div class="logo"></div>
        <div class="logo-txt">公会后台系统</div>
      </div>
      <menu-list v-if="$store.state.menus.length>0"   />
      </div>
    </div>
</template>

<script>

import menuList from './MenuList.vue'
import tool from '@/utils/tool'
export default {
  name: "Slide",
  components: { 
    menuList 
  },
  data() {
    return {
      
    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.slide-container {
  .container{
    position: fixed;
    width: 300px;
    height: 100vh;
    overflow: hidden;
    background: #333333;
    padding-left: 40px;
    z-index: 999;
  .logo-container{
    margin-top: 40px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: flex-end;
    .logo{
      width: 40px;
      height: 40px;
      background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/common/logo.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .logo-txt{
      margin-left: 9px;
    }
  }
  }
}

@media screen and(max-width:750px) {
  .slide-container{
  .container{
    position: fixed;
     width: 256px;
    padding-left: 20px;
  }
}
}




</style>
